<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="图层名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['name',{rules: [{ required: true, message: '图层名称不能为空' }]}]" placeholder="请输入">
        </a-input>
      </a-form-item>
      <a-form-item label="地图资源" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select showSearch
                  allowClear
                  v-decorator="['mapInfo.id',{rules: [{ required: true, message: '地图资源不能为空' }]}]"
                  placeholder="请选择地图资源"
                  @change="mapInfoOnchange">
          <a-select-option v-for="item in mapInfoList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="图层父节点" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select showSearch
                  allowClear
                  v-decorator="['parentId']"
                  placeholder="请选择父节点">
          <a-select-option v-for="item in layerList" :key="item.key" :value="item.value">{{item.title}}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="设备类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          multiple
          treeCheckable
          :treeData="deviceCategoryTree"
          allowClear
          placeholder="请选择设备类型"
          v-decorator="['typeLabels.deviceCategoryIds', {trigger:'change'}]"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="设施类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          multiple
          treeCheckable
          :treeData="facilityTypeTree"
          allowClear
          placeholder="请选择设施类型"
          v-decorator="['typeLabels.facilityTypeIds', {trigger:'change'}]"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="其他" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          multiple
          treeCheckable
          :treeData="otherOptionTree"
          allowClear
          placeholder="请选择"
          v-decorator="['typeLabels.otherOptionIds', {trigger:'change'}]"
        ></a-tree-select>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { amapLayerFormVO } from './common/common'
  import SERVICE_URLS from '../../../api/service.url'

  export default {
    name: 'LayerForm',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data() {
      return {
        mapInfoId: '',
        layerList: [],
        mapInfoList: [],
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        spinShow: false,
        formItem: amapLayerFormVO(),
        form: this.$form.createForm(this),
        deviceCategoryTree: [],
        deviceCategoryTreeDefaultValue: [],
        facilityTypeTree: [],
        otherOptionTree: [
          { title: '巡检', value: 0, key: 'INSPECT' },
          { title: '车辆', value: 1, key: 'VEHICLE' }
        ]
      }
    },
    mounted() {
      this.loadMapSelect()
      this.loadDeviceCategoryIdTree()
      this.loadFacilityTypeTree()
    },
    methods: {
      loadMapSelect() {
        this.$http(this, {
          url: SERVICE_URLS.mapInfo.select,
          noTips: true,
          success: (data) => {
            this.mapInfoList = data.body
          }
        })
      },
      /**
       * 加载详情
       */
      loadData(id) {
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.layer.view,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.loadLayer(data.body.mapInfo.id)
              this.setFields(data.body)
            })
            this.spinShow = false
          }
        })
      },
      /**
       * 加载图层
       */
      loadLayer(id) {
        this.mapInfoId = id
        this.$http(this, {
          url: SERVICE_URLS.layer.searchByMapInfoId,
          params: {
            mapInfoId: id
          },
          noTips: true,
          success: (data) => {
            this.layerList = data.body
          }
        })
      },
      /**
       * 加载设备类型
       */
      loadDeviceCategoryIdTree() {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentTypeApi.search,
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.deviceCategoryTree = data.body.children
            })
          }
        })
      },
      /**
       * 加载设施类型
       */
      loadFacilityTypeTree() {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.facilityTypeTree,
          noTips: true,
          success: (data) => {
            console.log(data.body)
            this.$nextTick(() => {
              this.facilityTypeTree = data.body
            })
          }
        })
      },
      submitAdd() {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.layer.create,
              noTips: true,
              data: this.formItem,
              success: (data) => {
                this.formItem = amapLayerFormVO()
                this.$emit('addSuccess')
                this.form.resetFields()
                this.spinShow = false
              },
              error: (data) => {
                this.$emit('addError')
                this.form.resetFields()
                this.spinShow = false
              }
            })
          } else {
            this.spinShow = false
          }
        })
      },
      submitEdit() {
        this.spinShow = true
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.layer.update,
              data: this.formItem,
              params: {
                id: this.formItem.id
              },
              noTips: true,
              success: (data) => {
                this.$emit('editSuccess', data.body)
                this.spinShow = false
              },
              error: (data) => {
                this.$emit('editError')
                this.spinShow = false
              }
            })
          } else {
          }
        })
      },
      mapInfoOnchange(value) {
        if (value) {
          this.loadLayer(value)
        }
      },
      setVOFields(values) {
        Object.assign(this.formItem, values)
      },
      setFields(values) {
        Object.assign(this.formItem, values)
        let val = JSON.parse(this.formItem.typeLabels)
        this.form.setFieldsValue({
          name: this.formItem.name,
          'mapInfo.id': this.formItem.mapInfo.id,
          parentId: this.formItem.parentId,
          'typeLabels.deviceCategoryIds': val.deviceCategoryIds,
          'typeLabels.facilityTypeIds': val.facilityTypeIds,
          'typeLabels.otherOptionIds': val.otherOptionIds
        })
      }
    }
  }
</script>

<style scoped>

</style>