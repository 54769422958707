<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">地图图层管理</h1>
    </div>
    <div slot="extra">
      <a-button @click="handleAdd" icon="plus" type="primary">新增图层</a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="图层名称">
              <a-input v-model="searchParams.name" @change="search" placeholder="请输入图层名称"/>
            </a-form-item>
            <a-form-item label="所属地图">
              <a-select style="width: 210px"
                        allowClear
                        showSearch
                        v-model="searchParams.mapInfoId"
                        placeholder="请选择所属地图"
                        @change="search">
                <a-select-option v-for="item in mapInfoList" :key="item.id" :value="item.id">{{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table size="middle"
               bordered
               :rowKey="record => record.id"
               @change="tableChange"
               :pagination="pagination"
               :columns="columns"
               :dataSource="tableData"
               :loading="tableLoading">
        <template slot="_index" slot-scope="text, record, index">
          {{searchParams.number*searchParams.size+index+1}}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button @click="handleEdit(record.id)" shape="circle" icon="edit" size="small"></a-button>
          <a-divider type="vertical"/>
          <a-button type="danger" @click="handleDelete(record.id)" shape="circle" icon="delete"
                    size="small"></a-button>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="添加图层基本信息"
             :visible="addModal"
             :maskClosable="false"
             @ok="handleAddOk"
             @cancel="closeAddModal">
      <Form ref="addRef" type="add"
            @addSuccess="addSuccess"
            @addError="addError">
      </Form>
    </a-modal>
    <!--修改Model-->
    <a-modal title="修改图层基本信息"
             :visible="editModal"
             :maskClosable="false"
             @ok="handleEditOk"
             @cancel="closeEditModal">
      <Form ref="editRef" type="edit"
            @editSuccess="editSuccess"
            @editError="editError">
      </Form>
    </a-modal>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import entityCRUD from '../../common/mixins/entityCRUD'
  import { amapLayerColumns } from './common/common'
  import PageLayout from '@/components/page/PageLayout'
  import Form from './Form'

  export default {
    name: 'List',
    mixins: [entityCRUD],
    components: { PageLayout, Form },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.layer,
        searchParams: {
          number: '0',
          size: '10',
          direction: 'ASC',
          order: 'parentId',
          name: null,
          mapInfoId: undefined,
        },
        mapInfoList: [],
        initColumns: amapLayerColumns(),
      }
    },
    created () {
      this.loadMapSelect()
    },
    methods: {
      /**
       * 加载地图下拉列表
       */
      loadMapSelect() {
        this.$http(this, {
          url: SERVICE_URLS.mapInfo.select,
          noTips: true,
          success: (data) => {
            this.mapInfoList = data.body
          }
        })
      }
    }
  }
</script>